/*
 * @Author: minihorse
 * @Date: 2020-12-09 15:30:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-03 14:38:43
 * 
 */
const Layout = () => import('../pages/layout')
// const Mobile = () => import('../pages/layout/mindex')
const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },    
    {
        path: '/login',
        component: () => import(/* webpackChunkName:'second' */ '../pages/login/index.vue')
    },
    {
        path: '/index',
        redirect:'/index/index'
    },
    // {
    //     path: '/mindex',
    //     redirect:'/mbaobei/list'
    // },
    {
        path: '/index',
        component: Layout,
        children: [
            {
                path: 'index',
                meta: {
                    title: '菜单列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/index/index')
            },
        ]
    },
    {
        path: '/menu',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '菜单列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/menu/list')
            },
        ]
    },
    {
        path: '/employee',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '员工列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/employee/list')
            },
        ]
    },
    {
        path: '/department',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '部门列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/department/list')
            },
        ]
    },
    {
        path: '/operator',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '操作员列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/list')
            },
            {
                path: 'group',
                meta: {
                    title: '会员组列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/group')
            },
        ]
    },
    {
        path: '/setting',
        component: Layout,
        children: [
            { // 总管理员设置全局参数
                path: 'list',
                meta: {
                    title: '参数设置'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/list')
            },
            { // 总管理员设置全局参数
                path: 'annualleave',
                meta: {
                    title: '年假设置'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/annualleave')
            },
            { // 总管理员设置全局参数
                path: 'section',
                meta: {
                    title: '版块管理'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/section')
            },
        ]
    },
    {
        path: '/record',
        component: Layout,
        children: [
            {
                path: 'login',
                meta: {
                    title: '操作员登录记录'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/record/login')
            },
        ]
    },
    /* 本段代码放在最后，未设置路由的地址跳转404 */
    {
        path:'*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '页面不存在'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/404')
            },
        ]
    },
    /** 本段代码放在最后，未设置路由的地址跳转404 */
]
export default staticRoutes